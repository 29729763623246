var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ProfileImageBar", {
        on: {
          refreshTables: function($event) {
            return _vm.refreshTables()
          }
        }
      }),
      _c("LeaveRequestsTable", { ref: "LeaveRequests" }),
      _c("ScheduledLeavesTable", { ref: "ScheduledLeaves" }),
      _c("BankHolidaysTable"),
      _c("PastLeavesTable", { ref: "PastLeaves" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }