var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            { staticClass: " pl-1 " },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "padding-top": "8px" }
                      },
                      [
                        _c("h4", { staticClass: " font-weight-700" }, [
                          _vm._v(" Past Leaves (" + _vm._s(_vm.total) + ") ")
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-overlay",
                        {
                          attrs: { show: _vm.tableLoading, rounded: "sm" },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(date)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.date))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(time)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "80px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.time))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("b-table", {
                            ref: "table",
                            staticClass: "mobile_table_css",
                            attrs: {
                              "current-page": _vm.currentPage,
                              "per-page": _vm.pagination.perPage,
                              fields: _vm.fields,
                              items: _vm.pastLeaves,
                              "sort-by": _vm.sortBy,
                              "sort-desc": _vm.sortDesc,
                              "sort-direction": _vm.sortDirection,
                              hover: "",
                              responsive: ""
                            },
                            on: {
                              "update:sortBy": function($event) {
                                _vm.sortBy = $event
                              },
                              "update:sort-by": function($event) {
                                _vm.sortBy = $event
                              },
                              "update:sortDesc": function($event) {
                                _vm.sortDesc = $event
                              },
                              "update:sort-desc": function($event) {
                                _vm.sortDesc = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _vm.pagination.totalRows !== 0
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.pagination.from) +
                                " to " +
                                _vm._s(_vm.pagination.to) +
                                " of " +
                                _vm._s(_vm.pagination.totalRows) +
                                " entries"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "mb-0 mt-1 mt-sm-0 ",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          "total-rows": _vm.pagination.totalRows,
                          "first-number": "",
                          "last-number": "",
                          "next-class": "next-item",
                          "prev-class": "prev-item"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function() {
                              return [
                                _c("feather-icon", {
                                  attrs: { icon: "ChevronLeftIcon", size: "18" }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "next-text",
                            fn: function() {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }