var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c(
                "b-row",
                {},
                [
                  _c("b-col", { attrs: { cols: "12", xl: "8" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-md-flex flex-row mb-2  text-md-left text-center"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "shadow-sm font-weight-bold p-1 align-self-baseline float-left",
                            attrs: { variant: "white" },
                            on: {
                              click: function($event) {
                                return _vm.$router.back()
                              }
                            }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "20" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mr-2" },
                          [
                            _c("b-avatar", {
                              staticClass: "mt-2 mr-3 mr-md-0",
                              attrs: {
                                src: _vm.getUserImage(_vm.userDetails.id),
                                size: "100px"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "m-2 font-weight-bolder profile-text"
                          },
                          [
                            _c("b-row", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center mt-1"
                                },
                                [
                                  _c("div", { staticClass: "mr-1" }, [
                                    _c(
                                      "h2",
                                      { staticClass: " font-weight-700 " },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.userDetails.first_name) +
                                            " " +
                                            _vm._s(_vm.userDetails.last_name) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          staticStyle: {
                                            "margin-top": "6px",
                                            "margin-left": "4px"
                                          },
                                          attrs: {
                                            variant: "primary",
                                            show: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "text-dark font-weight-700 mx-1 "
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.jobRoleName) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "b-row",
                              { staticStyle: { "margin-left": "1px" } },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: " mt-1" } },
                                  [
                                    _c("b-row", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center justify-content-xl-end"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center pr-bx",
                                              staticStyle: { color: "#171822" }
                                            },
                                            [
                                              _c(
                                                "b-avatar",
                                                {
                                                  attrs: {
                                                    variant: "light-primary",
                                                    rounded: ""
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "CalendarIcon",
                                                      size: "18"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm.editLeaveDaysShow
                                                    ? _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "d-flex"
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm.trackLeaveOnDays
                                                                  ? _c(
                                                                      "h5",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bolder",
                                                                        staticStyle: {
                                                                          "margin-top":
                                                                            "10px",
                                                                          color:
                                                                            "#171822",
                                                                          "font-size":
                                                                            "18px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.allocatedLeaveDays
                                                                            ) +
                                                                            " days "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "h5",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bolder mr-1",
                                                                        staticStyle: {
                                                                          "margin-top":
                                                                            "10px",
                                                                          color:
                                                                            "#171822",
                                                                          "font-size":
                                                                            "18px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              parseFloat(
                                                                                _vm.allocatedLeaveHours
                                                                              ).toFixed(
                                                                                1
                                                                              )
                                                                            ) +
                                                                            "hrs "
                                                                        )
                                                                      ]
                                                                    )
                                                              ]),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn-icon bg-white text-primary",
                                                                      attrs: {
                                                                        size:
                                                                          "20",
                                                                        variant:
                                                                          "outline-white"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.editLeaveDays()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "feather-icon",
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "EditIcon",
                                                                            size:
                                                                              "15"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "mb-0 mr-n4"
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group",
                                                                {
                                                                  staticClass:
                                                                    "input-group-merge"
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        size:
                                                                          "sm",
                                                                        placeholder:
                                                                          "",
                                                                        type:
                                                                          "number"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.editNoOfLeaveDays,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.editNoOfLeaveDays = $$v
                                                                        },
                                                                        expression:
                                                                          "editNoOfLeaveDays"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "b-input-group-append",
                                                                    {
                                                                      attrs: {
                                                                        "is-text":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "feather-icon",
                                                                        {
                                                                          staticClass:
                                                                            "text-primary",
                                                                          staticStyle: {
                                                                            cursor:
                                                                              "pointer"
                                                                          },
                                                                          attrs: {
                                                                            icon:
                                                                              "CheckIcon",
                                                                            size:
                                                                              "15"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.editLeaveDays()
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                  _c("small", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trackLeaveOnDays ===
                                                          true
                                                          ? "Allocated leave days"
                                                          : "Allocated leave hours"
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center pr-bx"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm.trackLeaveOnDays
                                                    ? _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "mb-0 font-weight-bolder p",
                                                          staticStyle: {
                                                            color: "#171822",
                                                            "font-size": "18px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                parseFloat(
                                                                  _vm.usedLeaveDays
                                                                ).toFixed(1)
                                                              ) +
                                                              " days "
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "mb-0 font-weight-bolder ",
                                                          staticStyle: {
                                                            color: "#171822",
                                                            "font-size": "18px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                parseFloat(
                                                                  _vm.usedLeaveHours
                                                                ).toFixed(1)
                                                              ) +
                                                              "hrs "
                                                          )
                                                        ]
                                                      ),
                                                  _c("small", [_vm._v("Used")])
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mr-1"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm.trackLeaveOnDays
                                                    ? _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "mb-0 font-weight-bolder ",
                                                          staticStyle: {
                                                            color: "#171822",
                                                            "font-size": "18px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                parseFloat(
                                                                  _vm.allocatedLeaveDays -
                                                                    _vm.usedLeaveDays
                                                                ).toFixed(1)
                                                              ) +
                                                              " days "
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "mb-0 font-weight-bolder ",
                                                          staticStyle: {
                                                            color: "#171822",
                                                            "font-size": "18px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                parseFloat(
                                                                  _vm.remainingLeaveHours
                                                                ).toFixed(1)
                                                              ) +
                                                              "hrs "
                                                          )
                                                        ]
                                                      ),
                                                  _c("small", [
                                                    _vm._v("Remaining")
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center mr-1"
                                            },
                                            [
                                              _c(
                                                "b-avatar",
                                                {
                                                  attrs: {
                                                    variant: "light-warning",
                                                    rounded: ""
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "ClockIcon",
                                                      size: "18"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "mb-0 font-weight-bolder ",
                                                      staticStyle: {
                                                        color: "#171822",
                                                        "font-size": "18px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.leaveRequestCount
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("small", [
                                                    _vm._v("Leave Requests")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center"
                                            },
                                            [
                                              _c(
                                                "b-avatar",
                                                {
                                                  attrs: {
                                                    variant: "light-danger",
                                                    rounded: ""
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "HeartIcon",
                                                      size: "18"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm.trackLeaveOnDays
                                                    ? _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "mb-0 font-weight-bolder ",
                                                          staticStyle: {
                                                            color: "#171822",
                                                            "font-size": "18px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                parseFloat(
                                                                  _vm.sicknessLeaveDays
                                                                ).toFixed(1)
                                                              ) +
                                                              " days "
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "mb-0 font-weight-bolder ",
                                                          staticStyle: {
                                                            color: "#171822",
                                                            "font-size": "18px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                parseFloat(
                                                                  _vm.sicknessLeaveHours
                                                                ).toFixed(1)
                                                              ) +
                                                              "hrs "
                                                          )
                                                        ]
                                                      ),
                                                  _c("small", [
                                                    _vm._v("Sickness Leaves")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass: "pt-0 pb-1 pt-xl-1",
                      attrs: { cols: "12", xl: "4" }
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", {
                            staticClass:
                              "d-flex justify-content-center justify-content-xl-end",
                            attrs: { cols: "12", md: "5" }
                          }),
                          _c(
                            "b-col",
                            {
                              staticClass: " pr-5 pl-5   my-2 my-md-0",
                              attrs: { cols: "12", md: "7" }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-block ",
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleSideBars()
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "align-middle" }, [
                                    _vm._v("+ Add Leave")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AddLeaveSidebar", {
        ref: "AddLeaveSidebar",
        attrs: { "is-add-leave-sidebar-active": _vm.isAddLeaveSidebarActive },
        on: {
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          },
          dataRefresh: function($event) {
            return _vm.dataRefresh()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }